import React, { useEffect, useState, useContext } from 'react'
import { launchPopup } from '@trinsic/web-ui'
import { Text, Button, Box, Stack, Timeline, Group, Paper, Image } from '@mantine/core'
import { DataContext } from '../../../../context/DataContext'
import { IconCheck } from '@tabler/icons-react'
import CaDmvImage from '../../../../assets/ca_mdl.png'
import useStatusCheck from '../../../../hooks/useStatusCheck'

const CaMdl = () => {
  const { data, settings } = useContext(DataContext)
  const [launchUrl, setLaunchUrl] = useState(null)
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [sessionResults, setSessionResults] = useState(null)

  // 1. Control when your polling starts (after button click)
  const [shouldStartPolling, setShouldStartPolling] = useState(false)
  // The second param is false, the third is 'idmax' just like your example usage
  useStatusCheck(shouldStartPolling, false, 'idmax')

  useEffect(() => {
    if (launchUrl) return

    const fetchUrl = async () => {
      try {
        // Avoid mutating context data directly
        const newData = {
          ...data,
          provider: 'CA-DMV'
        }
        const response = await fetch(
          process.env.REACT_APP_BACKEND_URL + '/v3/stable/api/frontend/idmax',
          {
            method: 'POST',
            body: JSON.stringify(newData)
          }
        )

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        const resultData = await response.json()

        const redirectUrl = encodeURIComponent(
          `${process.env.REACT_APP_FRONTEND_URL}/mdl/ca/return?id=1234`
        )

        // Append the redirect URL to your request URL
        const updatedLaunchUrl = `${resultData.continuations.info.template.info.request_url}&redirecturl=${redirectUrl}`
        setLaunchUrl(updatedLaunchUrl)
       
        setIsButtonEnabled(true)
      } catch (error) {
        console.error('Error fetching status:', error)
      }
    }

    fetchUrl()
  }, [launchUrl, data])

  const handleLaunchPopup = async () => {
    if (!launchUrl) return

    setIsLoading(true)
    setMessage('Launching popup...')

    let popupWindow
    const originalWindowOpen = window.open
    window.open = (...args) => {
      popupWindow = originalWindowOpen(...args)
      return popupWindow
    }


 
    try {
      setShouldStartPolling(true)
      await launchPopup(() => launchUrl)
    } catch (error) {
      console.error('Error launching popup:', error)
    } finally {
      console.log("finally hit")
      //window.open = originalWindowOpen
    }
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
    >
      <Box>
        <Stack>
          <Stack spacing={4}>
            <Text c="dimmed" fz="md">
              Verify with
            </Text>
            <Group spacing={8}>
              <Image src={CaDmvImage} width={45} />
              <Text fz="xl">California DMV Wallet</Text>
            </Group>
          </Stack>

          <Text fz="md" fw={700}>
            How Verification with California DMV Wallet Works
          </Text>
          <Paper mt={0} withBorder p="sm" style={{ textAlign: 'center' }}>
            <Timeline radius="md" active={4} lineWidth={3} bulletSize={16}>
              <Timeline.Item
                bullet={<IconCheck size={12} />}
                title={<Text fz="sm">Click below to open the DMV popup</Text>}
              />
              <Timeline.Item
                bullet={<IconCheck size={12} />}
                title={<Text fz="sm">Open the CA DMV wallet mobile app</Text>}
              />
              <Timeline.Item
                bullet={<IconCheck size={12} />}
                title={<Text fz="sm">Tap "Scan website QR Code to verify"</Text>}
              />
              <Timeline.Item
                bullet={<IconCheck size={12} />}
                title={<Text fz="sm">Share your verified details</Text>}
              />
            </Timeline>
          </Paper>
        </Stack>
      </Box>
      <Box>
        <Button
          onClick={handleLaunchPopup}
          disabled={!isButtonEnabled || isLoading}
          style={{ backgroundColor: '#5b9ad3' }}
          size='lg'
          fullWidth
        >
          {isLoading ? 'Loading...' : 'Launch California DMV Popup'}
        </Button>
      </Box>
    </Box>
  )
}

export default CaMdl