import React, { useContext, useState, useEffect, useRef } from "react";
import { Button, Alert, Text } from "@mantine/core";
import {
  createCaptureSdk,
  createCaptureUiWithInstance,
} from "@microblink/capture";
import "@microblink/capture/style.css";
import { uploadImageToS3 } from "../../../../helpers";
import { DataContext } from "../../../../context/DataContext";
import { IconExclamationCircle } from "@tabler/icons-react";

export default function Microblink({ onComplete, onStart, onUpload }) {
  const { settings, text } = useContext(DataContext);
  const [sdk, setSdk] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function processAndUpload(data, width, height, suffix) {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    ctx.putImageData(new ImageData(data, width, height), 0, 0);
    const blob = await new Promise((resolve) =>
      canvas.toBlob(resolve, "image/jpeg", 1.0)
    );
    await uploadImageToS3(`${settings.request_id}_dl_${suffix}.jpeg`, blob);
  }

  async function sendResultToApi(requestId, documentGroup) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v3/stable/api/frontend/microblink/stats`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            request_id: requestId,
            document_group: documentGroup,
          }),
        }
      );
      const result = await response.json();
      if (result.result !== "success") {
        console.error("Failed to send result to API:", result);
      }
    } catch (error) {
      await sendResultToApi(settings.request_id, error.message);
      console.error("Error while sending result to API:", error);
    }
  }

  const captureComponentRef = useRef(null);

  useEffect(() => {
    // Preload the SDK
    async function preloadSdk() {
      setIsLoading(true);
      try {
        const loadedSdk = await createCaptureSdk({
          licenseKey:
            window.location.hostname === "vx-fe.dcams.app"
              ? process.env.REACT_APP_MB_KEY_VX
              : process.env.REACT_APP_MB_KEY,
          resourceUrl: `${window.location.protocol}//${window.location.hostname}:${window.location.port}`,
        });

        if(settings.site_name === 'GAVS-PROD-TEST' || settings.site_name === "GAVS-PROD-RO"){
          await loadedSdk.updateAnalyzerSettings({
            blurPolicy: "disabled" // Disables blur detection
          });
        }

        setSdk(loadedSdk);
      } catch (error) {
        await sendResultToApi(settings.request_id, error.message);
        setErrorMessage("Failed to initialize the SDK: " + error.message);
      }
      setIsLoading(false);
    }

    preloadSdk();

    // Cleanup on component unmount
    return () => {
      captureComponentRef.current?.dismount();
    };
  }, []);

  async function startCapture() {
    onStart();
    if (!sdk) {
      await sendResultToApi(
        settings.request_id,
        "SDK not initialized, please reload the page."
      );
      setErrorMessage("SDK not initialized, please reload the page.");
      return;
    }
    await sendResultToApi(settings.request_id, "SDK loaded");

    try {
      const component = await createCaptureUiWithInstance(sdk, {
        target: undefined,
        showTutorial: false,
        showErrorDialog: false,
        destroyInstanceOnDismount: true,
        localization: {
          ...text.captureMessages,
          ...text.tutorialMessages,
          ...text.cameraMessages,
          ...text.sharedMessages,
        },
      });

      component.captureSdk?.subscribe(
        (s) => s.errorState,
        async (x) => {
          if (x?.message !== undefined) {
            setErrorMessage(x?.message);
            console.error("Error from the SDK: ", x?.message);

            try {
              await sendResultToApi(settings.request_id, x?.message);
            } catch (error) {
              console.error("Error sending result to API:", error);
            }

            component.dismount();
          }
        }
      );

      component.captureSdk?.setCallbacks({
        onCaptureResult: async (result) => {
          await sendResultToApi(settings.request_id, result.documentGroup);

          if (
            settings.journey.settings?.checkMbDocumentGroup &&
            result.documentGroup === "unknown"
          ) {
            component.dismount();
            setErrorMessage("The document type is unknown. Please try again.");
            return;
          }

          component.dismount();
          onUpload();
          await processAndUpload(
            result.firstCapture.imageResult.data,
            result.firstCapture.imageResult.width,
            result.firstCapture.imageResult.height,
            "front"
          );
          if (result.secondCapture?.imageResult) {
            await processAndUpload(
              result.secondCapture.imageResult.data,
              result.secondCapture.imageResult.width,
              result.secondCapture.imageResult.height,
              "back"
            );
          }
          onComplete();
        },
      });

      captureComponentRef.current = component;
    } catch (error) {
      await sendResultToApi(settings.request_id, error.message);
      setErrorMessage("Failed to start the capture process: " + error.message);
    }
  }

  return (
    <>
      {errorMessage && (
        <Alert
          icon={<IconExclamationCircle />}
          color="red"
          title={errorMessage}
        >
          {errorMessage !==
            "The document type is unknown. Please try again." && (
            <Text>{text.dcams.errors.permissions}</Text>
          )}
        </Alert>
      )}
      <Button
        size="lg"
        fullWidth
        onClick={startCapture}
        loading={isLoading}
        loaderPosition="left"
      >
        {text.dcams.button}
      </Button>
    </>
  );
}
